<template>
       <div>
  <b-modal id="modal-cantidad" ref="modal" title="cantidad de muestras" size="xl" >
    <template #modal-header="{ close }">
      <!-- Emulate built-in modal header close button action -->
      <b-row class="d-flex justify-content-around">
        <div class="pl-3">Cantidad de muestras</div>
      </b-row>
      <button type="button" class="close" aria-label="Close" @click="close()">
        <span aria-hidden="true" style="color:white">&times;</span>
      </button>
    </template>
    <div>
      <b-table :items="tablaItems" :fields="tablaFields">
        <template #cell(orden)="row">
          <b-form-input v-model="row.item.orden" type="number" min="1"></b-form-input>
        </template>
        <template #cell(identificacion)="row">
          <b-form-input v-model="row.item.identificacion" placeholder="Ingrese la identificación de la muestra."></b-form-input>
        </template>        
        <template #cell(PYM)="row">
          <!-- Si 'row.item.PYM' está vacío o no es un array, mostramos un mensaje -->
          <template v-if="row.item.PYM.length === 0">
            <b-list-group-item>No hay parametros seleccionados.</b-list-group-item>
          </template>
          <template v-else>
            <b-list-group>
            <!-- Utilizamos la directiva 'v-b-toggle' para habilitar la funcionalidad de colapsar -->
            <!-- Establecemos 'visible' en true para el primer elemento del grupo -->
            <b-list-group-item v-b-toggle.collapse-pym :visible="row.item.PYM.length > 0">
              <strong>{{ row.item.PYM[0].parametro }}</strong> - {{ row.item.PYM[0].metodologia }}
            </b-list-group-item>
            <!-- Agregamos un colapsable 'b-collapse' al grupo con el ID 'collapse-pym' -->
            <b-collapse id="collapse-pym">
              <!-- Iteramos sobre el resto de elementos del array 'row.item.PYM' -->
              <!-- Mostramos el parámetro y la metodología de cada elemento -->
              <template v-for="item in row.item.PYM.slice(1)">
                <b-list-group-item :key="item.id_parametro">
                  <strong>{{ item.parametro }}</strong> - {{ item.metodologia }}
                </b-list-group-item>
              </template>
            </b-collapse>
          </b-list-group>
          </template>
      
        </template>
        <template #cell(accion)="row">
          <b-dropdown right size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                  <b-icon style="height: 80%; width: 80%; align-items: center;" icon="three-dots" variant="dark" aria-hidden="true"></b-icon>
              </template>
              <b-dropdown-item v-if="row" @click="abrirParam(row.item)">
                   <b-icon icon="clipboard" aria-hidden="true" class="mr-2"></b-icon>Administrar parámetros
              </b-dropdown-item>                                                                                                                    
          </b-dropdown>
        </template> 
        
        
      </b-table>
    </div> 
    <b-alert variant="success" :show="guardadoExitoso" dismissible @dismissed="guardadoExitoso = false">
  Se han guardado los datos con éxito.
</b-alert>
    <template #modal-footer="{ close }" >
      <b-button @click="guardarFormulario()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">Guardar</b-button>
      <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">
        Cerrar
      </b-button>
    </template>
    
  </b-modal>

  <b-modal id="modal-Agregar-Parametros" ref="mod" :title="`Agregar parámetros a analistas`" size="lg">
                    <template #modal-header="{ close }">
                        <b-row class="d-flex justify-content-around">
                            <div class="pl-3">Asignar parámetros a la submuestra</div>
                        </b-row>
                        <button type="button" class="close" aria-label="Close" @click="close()">
                            <span aria-hidden="true" style="color:white">&times;</span>
                        </button>
                    </template>
                    <b-row>
                        <b-col>
                            <b-form-group label="Seleccione un parámetro:">
                                <b-form-select v-model="parametroSeleccionadoIngreso" :options="TodasopcionesParametro" text-field="parametro" value-field="id_parametro" ></b-form-select>
                            </b-form-group>
                        </b-col>
                        
                        <b-col>
                            <b-form-group label="Seleccione una metodología:">
                                <b-form-select v-model="metodologiaSeleccionadoIngreso" :options="opcionesMetodologia" :disabled="metodologiaDeshabilitada" text-field="metodologia" value-field="id_metodologia" @change="agregar(filaSeleccionada)"></b-form-select>
                            </b-form-group>
                        </b-col>  
                    </b-row>

                    <b-row v-if="filaSeleccionada && filaSeleccionada.PYM.length > 0" class="mt-3">
                        <b-col>
                            <b-form-group label="Parámetros seleccionados:">
                                <div v-for="(objeto, index) in filaSeleccionada.PYM" :key="index" class="d-flex align-items-center objetos-item mb-3">
                                    <b-input readonly :value="objeto.parametro" class="mr-2"></b-input>
                                    <b-input readonly :value="objeto.metodologia" class="mr-2"></b-input>                                     
                                    <b-button variant="danger" @click="eliminarElementoPYM(filaSeleccionada, index)" class="ml-2">
                                        <b-icon-trash-fill></b-icon-trash-fill>
                                    </b-button>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                
                    <b-alert variant="danger" :show="alertaDuplicado" dismissible @dismissed="alertaDuplicado = false">
                        El parámetro ya se encuentra agregado.
                    </b-alert>
                
                    <b-alert variant="success" :show="alertaExito" dismissible @dismissed="alertaExito = false">
                        Parámetro agregado con éxito.
                    </b-alert>
                    <!-- //////////////////////////////////////////MODAL-FOOTER////////////////////////////////////////////////////////////////////////////////// -->
                    <template #modal-footer="{ close }">                        
                        <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">
                          Cerrar
                        </b-button>
                    </template>
                </b-modal>
              </div>

  
  
</template>



<script>

export default {
  data() {
    return {
      parametrosOptions: [],
        metodologiasOptions: [],
        identificacion: '',
        orden: 0,
        identificacionesArray: [],
        parametros_metodologias: [],
        TodasopcionesParametro: [],
        objetossubSeleccionados: [],
        parametroSeleccionado: '',
        metodologiaSeleccionada: '',
        opcionesMetodologia: [],
        alertaDuplicado: false,
            alertaExito: false,
            alertaExiste: false,
            alertaNOexiste: false,
            metodologiaDeshabilitada: true,
            parametroSeleccionadoIngreso: '',
            filaSeleccionada: null,
            metodologiaSeleccionadoIngreso: '',
            metodologiasData: [],
            index: null,
            guardadoExitoso: false,
        
        
    };
  },

  props: {
    nMuestras: {
      type: Number,
      default: 1,
    },
    objetosSeleccionados: {
      type: Array,
    },
    identificaciones: {
      type: Array,
    },
    submuestra_agregar: {
      type: Array,
    },
    opcionesParametrosSeleccionados: {
      type: Array,
    },
    actualizarModal: {
      type: Number,
      default: 0,
    },
  },

  mounted(){
    this.$refs.modal.$on('show', () => {
      if (this.identificaciones.length > 0){
        this.identificacionesArray = JSON.parse(JSON.stringify(this.identificaciones));
        console.log('Identificaciones recibidas: ', this.identificacionesArray);
      }
    });
    this.obtenerParametro();
    
  },
  watch: {
    objetosSeleccionados:{
      handler(nuevosObjetosSeleccionados) {
          const parametrosSet = new Set(nuevosObjetosSeleccionados.map(p => p.parametro));
          const metodologiasSet = new Set(nuevosObjetosSeleccionados.map(m => m.metodologia));
          this.parametrosOptions = [...parametrosSet];
          this.metodologiasOptions = [...metodologiasSet];
        },
        immediate: true
    },
    identificaciones:{
      handler(identificaciones_nuevas) {
        if (this.identificaciones.length > 0){
        this.identificacionesArray = JSON.parse(JSON.stringify(identificaciones_nuevas));
      }
        },
        immediate: true
    },
    actualizarModal:{
      handler(numero) {
        console.log(numero);
        },
        immediate: true
    },
    opcionesParametrosSeleccionados:{
      handler(opcionesParametrosSeleccionados_nuevos) {
        this.TodasopcionesParametro = opcionesParametrosSeleccionados_nuevos;
        },
        immediate: true
    },
    parametroSeleccionadoIngreso: function (newParametro) {
            if (newParametro) {
                this.actualizarMetodologias();
                this.metodologiaDeshabilitada = false; // Habilita el input 
                console.log("xd")
            } else {
                this.metodologiaDeshabilitada = true;
            }
        },
  },
  computed: {
      tablaItems() {
        this.actualizarModal;
        const items = [];
        for (let i = 1; i <= this.nMuestras; i++) {
          const identificacion = this.identificacionesArray[i - 1] || '';
          items.push({
            orden: i,
            identificacion,
            parametros: this.parametrosOptions[0] || '',
            metodologias: this.metodologiasOptions[0] || '',
            PYM: this.submuestra_agregar[i-1]?.parametros_agregar ,
          });
        }
        return items;
      },
      tablaFields() {
        const fields = [
          { key: 'orden', label: 'Orden', thClass: 'text-center', tdClass: 'text-center', editable: true },
          { key: 'identificacion', label: 'Identificación', thClass: 'text-center', tdClass: 'item-center' },
        ];
        fields.push({ key: 'PYM', label: 'Parámetros y metodologías', thClass: 'text-center', tdClass: 'item-center' });
        fields.push({ key: 'accion', label: 'Acción', thClass: 'text-center', tdClass: 'item-center' });
        return fields;          
      },      
    },

    methods: {
      guardarFormulario() {
      const datosIngresados = this.tablaItems.map((item) => {        

        // Capturamos los id_parametro e id_metodologia del PYM
        const PYM = item.PYM.map(pymItem => ({
          id_parametro : pymItem.id_parametro,
          id_metodologia : pymItem.id_metodologia,
          metodologia: pymItem.metodologia,
          parametro : pymItem.parametro,
        }));              

        return {
          identificacion: item.identificacion,
          orden: item.orden,
          parametros: item.parametros,
          parametros_agregar: PYM    
        };
      });
      
      console.log("dato a enviar: ", datosIngresados);

      this.$emit('datosIngresados', datosIngresados);

      this.guardadoExitoso = true;
      this.$refs.mod.hide();
    },
    eliminarElementoPYM(filaSeleccionada, index) {
      // Hacemos un slice del array PYM para eliminar el elemento en el índice dado
      filaSeleccionada.PYM = [...filaSeleccionada.PYM.slice(0, index), ...filaSeleccionada.PYM.slice(index + 1)];
    },
    abrirParam(row) {

    this.filaSeleccionada = row;
    
    this.$bvModal.show('modal-Agregar-Parametros');
    console.log("fila", this.filaSeleccionada);
    },

    onModalShown() {
      this.alertaExito = false;
      this.alertaDuplicado = false;
    },
       
    obtenerParametro() {

    },
      
    agregarObjetosSeleccionados() {
      if (this.parametroSeleccionado && this.metodologiaSeleccionada) {
        const ParamExistente = this.objetossubSeleccionados.find(objeto => objeto.parametro === this.parametroSeleccionado);
        const MetExistente = this.objetossubSeleccionados.find(objeto => objeto.metodologia === this.metodologiaSeleccionada);
        if (ParamExistente && MetExistente) {
          this.alertaDuplicado = true;
          this.parametroSeleccionado = '';
          this.metodologiaSeleccionada = '';
          this.alertaExito = false;
        } else {
          const parametroData = this.TodasopcionesParametro.find(item => item.id_parametro === this.parametroSeleccionado);
          const metodologiaCompleta = parametroData.metodologias.find(metodologia => metodologia.nombre_metodologia === this.metodologiaSeleccionada);
          console.log("parametroData:", parametroData)
          console.log("metodologiaCompleta:", metodologiaCompleta)
          this.objetossubSeleccionados.push({
              id_parametro: parametroData.id_parametro,
              parametro: this.parametroSeleccionado,
              metodologia: metodologiaCompleta.nombre_metodologia,
              id_metodologia: metodologiaCompleta.id_metodologia
          });
          this.parametros_agregar.push({
              id_parametro: parametroData.id_parametro,
              id_metodologia: metodologiaCompleta.id_metodologia,
          });
          console.log("las matrices ahn guardado lo siguiente: ", this.parametros_agregar)
          this.parametroSeleccionado = '';
          this.metodologiaSeleccionada = '';
          this.alertaDuplicado = false;
          this.alertaExito = true;
        }
      }
    },

    agregar(filaSeleccionada) {

      if( this.parametroSeleccionadoIngreso && this.metodologiaSeleccionadoIngreso){
        console.log("objetos seleccionados: ", this.parametroSeleccionadoIngreso , this.metodologiaSeleccionadoIngreso)
        const ParamExistente = this.filaSeleccionada.PYM.find(objeto => objeto.id_parametro === this.parametroSeleccionadoIngreso);
        const MetExistente = this.filaSeleccionada.PYM.find(objeto => objeto.id_metodologia === this.metodologiaSeleccionadoIngreso);

        if (ParamExistente && MetExistente) {
          this.alertaDuplicado = true;
          this.parametroSeleccionadoIngreso = '';
          this.metodologiaSeleccionadoIngreso = '';
          this.alertaExito = false;
          console.log("duplicados", ParamExistente , MetExistente)
        }else{
          const parametroData = this.TodasopcionesParametro.find(item => item.id_parametro === this.parametroSeleccionadoIngreso);
          const metodologiaCompleta = parametroData.metodologias.find(metodologia => metodologia.id_metodologia === this.metodologiaSeleccionadoIngreso);
          console.log("parametroData:", parametroData)
          console.log("metodologiaCompleta:", metodologiaCompleta)
          const nuevoElemento = {
            id_parametro: this.parametroSeleccionadoIngreso,
            id_metodologia: metodologiaCompleta.id_metodologia,
            parametro: parametroData.parametro,
            metodologia: metodologiaCompleta.metodologia,
          };

    // Llamamos al método para agregar el nuevo elemento a la fila seleccionada
    this.agregarElementoPYM(filaSeleccionada, nuevoElemento);
  
          console.log("nueva fila : ", filaSeleccionada.PYM)
          this.alertaExito = true;
          this.alertaDuplicado = false;
          this.parametroSeleccionadoIngreso = '';
          this.metodologiaSeleccionadoIngreso = '';   

        }


      }                 
          
    },
      agregarElementoPYM(filaSeleccionada, nuevoElemento) {
  // Creamos una copia del array PYM de la fila seleccionada
  const nuevoPYM = [...filaSeleccionada.PYM];

  // Agregamos el nuevo elemento al final del array nuevoPYM
  nuevoPYM.push(nuevoElemento);

  // Asignamos el nuevo array a la propiedad PYM de la fila seleccionada
  filaSeleccionada.PYM = nuevoPYM;
},
      actualizarMetodologias() {
          const parametro = this.parametroSeleccionadoIngreso;
          // Buscar el objeto correspondiente al parámetro seleccionado en metodologiasData
          const parametroData = this.TodasopcionesParametro.find(item => item.id_parametro === parametro);
          this.metodologias = parametroData.metodologias
          console.log("Metodologías den array:", this.metodologias);
          this.opcionesMetodologia = this.metodologias;
          console.log("opciones Metodologia:", this.opcionesMetodologia);
          /*if (parametroData.metodologias.length > 0) {
            // Obtener las metodologías asociadas al parámetro seleccionado
            const metodologias = parametroData.metodologias[0].metodologias;
            console.log("Metodologías asociadas al parámetro seleccionado:", metodologias);
            // Obtener solo los nombres de las metodologías
            this.opcionesMetodologia = metodologias.map(item => item.nombre_metodologia);
          } else {
            this.opcionesMetodologia = []; // No se encontraron metodologías para el parámetro seleccionado
          }*/
      },
    },  
  };
</script> 